import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const SliderWrapper = styled.div``;

export const ContentSliderWrapper = tw.div<{ $slideType?: any }>`
  bg-origin-border
  bg-full
  bg-no-repeat
  bg-left-top
  relative
  px-4
`;

export const PanelCurveTop = styled.div`
  position: absolute;
  width: 100%;
  top: 0;

  svg {
    width: 100%;
  }
`;

export const PanelCurveBottom = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  svg {
    width: 100%;
  }
`;

export const CurveWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CarouselImageContainer = styled.div`
  position: relative;

  ::before {
    content: '';
    display: block;
    padding-top: 56.25%; /* Aspect ratio of 16:9 (9 / 16 = 0.5625) */
  }
`;

const CarouselInnerTW = tw.div`
  rounded-2xl
  overflow-hidden
  relative
`;

export const CarouselInner = styled(CarouselInnerTW as any)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  picture {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Play = tw.span`
  sm:inset-y-1/3
  md:inset-y-1/3.2
  sm:inset-x-1/3.2
  md:inset-x-1/3.5
  absolute
  cursor-pointer
  sm:w-20
  sm:h-20
  md:w-108
  md:h-108
  z-10
`;

export const PlayContentSlider = tw.span`
  left-2/4
  top-2/4
  absolute
  cursor-pointer
  sm:w-20
  sm:h-20
  md:w-108
  md:h-108
  z-10
`;
PlayContentSlider.displayName = 'PlayContentSlider';

export const StyledPlayContentSlider = styled(PlayContentSlider as any)`
  transform: translate(-54px, -54px);
  @media (max-width: 767px) {
    transform: translate(-40px, -40px);
  }
`;
StyledPlayContentSlider.displayName = 'StyledPlayContentSlider';

export const VideoSlide = tw.div`
  relative
  h-full
`;
export const VideoSlideContent = tw.div`
  relative
  flex
  flex-col
  h-full
`;
VideoSlideContent.displayName = 'VideoSlideContent';

export const CarouselHeading = tw.div`
  flex
  flex-col
  items-center
  text-center
`;

export const CarouselCaptionWrapper = tw.div`
  inline
  w-full
`;

export const Caption = tw.p`
  text-center
  mt-4
  mb-4
  w-0
  min-w-full
`;

export const CarouselContentWrapper = tw.div`
  flex
  flex-col
  items-center
`;

export const CarouselCard = styled.div`
  margin-right: 15px;
  min-width: 100%;

  @media (min-width: 768px) {
    margin-right: 25px;
    min-width: 70%;
  }

  &:only-child {
    margin-right: 0;
  }
`;
CarouselCard.displayName = 'CarouselCard';
